import React, { useState, useEffect } from "react";
import "./App.scss";
import Header from "./components/Header";
import Footer from "./components/Footer";
import About from "./components/About";
import Experience from "./components/Experience";
import Projects from "./components/Projects";
import Skills from "./components/Skills";
import TriangleAnimation from "./components/animations/particles/TriangleAnimation";

function App() {
  const [resumeData, setResumeData] = useState({});
  const [sharedData, setSharedData] = useState({});

  function applyPickedLanguage(pickedLanguage, oppositeLangIconId) {
    swapCurrentlyActiveLanguage(oppositeLangIconId);
    document.documentElement.lang = pickedLanguage;
    const resumePath =
      document.documentElement.lang === window.$primaryLanguage
        ? `res_primaryLanguage.json`
        : `res_secondaryLanguage.json`;
    loadResumeFromPath(resumePath);
  }

  function swapCurrentlyActiveLanguage(oppositeLangIconId) {
    let pickedLangIconId =
      oppositeLangIconId === window.$primaryLanguageIconId
        ? window.$secondaryLanguageIconId
        : window.$primaryLanguageIconId;
    // document
    //   .getElementById(oppositeLangIconId)
    //   .removeAttribute("filter", "brightness(40%)");
    // document
    //   .getElementById(pickedLangIconId)
    //   .setAttribute("filter", "brightness(40%)");
  }

  async function loadResumeFromPath(path) {
    let res = await fetch(path);
    res = await res.json();
    setResumeData({ ...res });
  }

  async function loadSharedData() {
    let res = await fetch(`portfolio_shared_data.json`);
    res = await res.json();
    setSharedData({ ...res });
    document.title = `${res.basic_info.name}`;
  }

  useEffect(() => {
    loadSharedData();
    applyPickedLanguage(
      window.$primaryLanguage,
      window.$secondaryLanguageIconId
    );
  }, []);

  return (
    <>
      {sharedData.basic_info && resumeData.basic_info && (
        <div>
          <div>
            <Header sharedData={sharedData.basic_info} />
          </div>
          <div className="col-md-12 mx-auto text-center language d-none">
            {false && (
              <>
                <div
                  onClick={() =>
                    applyPickedLanguage(
                      window.$primaryLanguage,
                      window.$secondaryLanguageIconId
                    )
                  }
                  style={{ display: "inline" }}
                >
                  <span
                    className="iconify language-icon mr-5"
                    data-icon="twemoji-flag-for-flag-united-kingdom"
                    data-inline="false"
                    id={window.$primaryLanguageIconId}
                  ></span>
                </div>
                <div
                  onClick={() =>
                    applyPickedLanguage(
                      window.$secondaryLanguage,
                      window.$primaryLanguageIconId
                    )
                  }
                  style={{ display: "inline" }}
                >
                  <span
                    className="iconify language-icon"
                    data-icon="twemoji-flag-for-flag-poland"
                    data-inline="false"
                    id={window.$secondaryLanguageIconId}
                  ></span>
                </div>
              </>
            )}

            <div className=" py-5 my-5"></div>
          </div>
          <About
            resumeBasicInfo={resumeData.basic_info}
            sharedBasicInfo={sharedData.basic_info}
          />
          <Projects
            resumeProjects={resumeData.projects}
            resumeBasicInfo={resumeData.basic_info}
          />
          <Skills
            sharedSkills={sharedData.skills}
            resumeBasicInfo={resumeData.basic_info}
          />
          <Experience
            resumeExperience={resumeData.experience}
            resumeBasicInfo={resumeData.basic_info}
          />
          <Footer sharedBasicInfo={sharedData.basic_info} />
        </div>
      )}
    </>
  );
}

export default App;
