import React from "react";
import Particles from "react-tsparticles";
import { loadBubblesPreset } from "tsparticles-preset-bubbles";

function BubblesAnimation({ options }) {
  const particlesInit = async (main = options) => {
    await loadBubblesPreset(main);
  };

  const particlesLoaded = (container) => {
    console.log(container);
  };

  return (
    <Particles
      className="ts-particle"
      id="tsparticles"
      init={particlesInit}
      loaded={particlesLoaded}
      options={options}
    />
  );
}

BubblesAnimation.defaultProps = {
  options: {
    fullScreen: { enable: false },
    background: {
      position: "inherit",
      color: {
        value: "#000",
      },
    },

    preset: "bubbles",
  },
};

export default BubblesAnimation;
