import React from "react";
import Particles from "react-tsparticles";
import { loadTrianglesPreset } from "tsparticles-preset-triangles";

function TriangleAnimation({ options }) {
  const particlesInit = async (main = options) => {
    await loadTrianglesPreset(main);
  };

  const particlesLoaded = (container) => {
    console.log(container);
  };

  return (
    <Particles
      className="ts-particle"
      id="tsparticles"
      init={particlesInit}
      loaded={particlesLoaded}
      options={options}
    />
  );
}

TriangleAnimation.defaultProps = {
  options: {
    fullScreen: { enable: false },
    background: {
      position: "inherit",
    },

    preset: "triangles",
  },
};

export default TriangleAnimation;
